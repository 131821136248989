import React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
// import Link from '@bit/medicalwebexperts.mwe-ui.link';
import Card from '@bit/medicalwebexperts.mwe-ui.card';
// import BaseDivider from '@bit/medicalwebexperts.mwe-ui.divider';
import Image from '@bit/medicalwebexperts.mwe-ui.image';
// import { MdModeComment } from 'react-icons/md';
import SEO from '../components/Seo/Seo';
import Application from '../components/Application/Application';
import logoImg from '../images/logo.png';
import logoImg2x from '../images/logo@2x.png';

const LogoWrapper = styled.div(
  css({
    mb: 10,
  }),
);

// const Divider = styled(BaseDivider)(
//   css({
//     my: 8,
//   }),
// );

// const NumeredList = styled.ol(
//   css({
//     listStyleType: 'none',
//     m: 0,
//     p: 0,
//     pl: '28px',
//   }),
// );

// const NumeredListItem = styled.li(
//   css({
//     '&::before': {
//       alignItems: 'center',
//       bg: '#F0ECE7',
//       borderRadius: 'full',
//       color: 'secondary.main',
//       content: 'counter(inst)',
//       display: 'inline-flex',
//       fontSize: 'xs',
//       fontWeight: 'medium',
//       height: 5,
//       justifyContent: 'center',
//       ml: '-28px',
//       mr: 2,
//       width: 5,
//     },
//     color: 'text',
//     counterIncrement: 'inst',
//     fontFamily: 'body',
//     fontSize: 'md',
//     lineHeight: 'tall',
//     pb: 4,
//   }),
// );

// const List = styled.ul(
//   css({
//     listStyleType: 'disc',
//     m: 0,
//     p: 0,
//     pl: '18px',
//   }),
// );
// const ListItem = styled.li(
//   css({
//     color: 'text',
//     fontFamily: 'body',
//     fontSize: 'sm',
//     pb: 4,
//   }),
// );
// const InsideList = styled.ul(
//   css({
//     listStyleType: 'circle',
//     mx: 0,
//     mt: 3,
//     p: 0,
//     pl: 3,
//   }),
// );

const Main = styled(Card)(
  css({
    px: [2, null, null, 6],
    position: 'relative',
    zIndex: 'docked',
    boxShadow: '0 0 26px rgba(0,0,0,.1)',
    borderRadius: '20px',
  }),
);

const TextGroup = styled.div(
  css({
    mb: 8,
  }),
);

// const FaqWrapper = styled.div(
//   css({
//     p: 5,
//     borderRadius: 'lg',
//     bg: 'white',
//     my: 5,
//   }),
// );

// const AlertText = styled(Typography)(
//   css({
//     p: 4,
//     color: 'primary.light',
//     bg: 'rgba(40,136,221,.1)',
//     borderRadius: 'lg',
//   }),
// );

// const Question = styled.strong(
//   css({
//     display: 'flex',
//     alignItems: 'center',
//   }),
// );

// const QuestionIcon = styled(MdModeComment)(
//   css({
//     mr: 2,
//     opacity: '.1',
//   }),
// );

// const Answer = styled(Typography)(
//   css({
//     pl: 8,
//   }),
// );

// const AnswerList = styled(List)(
//   css({
//     pl: 12,
//     fontSize: 'md',
//   }),
// );

const IndexPage = () => (
  <>
    <SEO title="Apply Online for Repatriation to American Samoa" />
    <Flex flexDirection={['column', null, null, 'row']}>
      <Flex.Box width={['100%', null, null, '40%']} pr={[0, null, null, 10]}>
        <LogoWrapper>
          <Image
            src={logoImg}
            srcSet={`${logoImg} 1x, ${logoImg2x} 2x`}
            alt="American Samoa Departament of Health"
            responsive
          />
        </LogoWrapper>
        <Typography variant="h1" mb={5}>
          Traveler Health Declaration
          <br />
          <strong>American Samoa</strong>
        </Typography>
        <TextGroup>
          <Typography paragraph>
            All persons entering American Samoa must complete this form before arrival.
          </Typography>
        </TextGroup>

      </Flex.Box>
      <Flex.Box width={['100%', null, null, '100%']}>
        <Main>
          <Card.Body>
            <Application />
          </Card.Body>
        </Main>
      </Flex.Box>
    </Flex>
  </>
);

export default IndexPage;
